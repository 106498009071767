import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomeScreen from "../pages/home";
import LoginScreen from "../pages/auth/login";
import RegisterScreen from "../pages/auth/register";

const PageRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/auth/:type" element={<LoginScreen />} />
                <Route path="/account/register" element={<RegisterScreen />} />
            </Routes>
        </Router>
    );
};

export default PageRoutes;