import * as React from "react";
import {
    AppBar,
    Box,
    Stack,
    Toolbar,
    Icon,
    CssBaseline,
    TextField,
    InputAdornment,
} from "@mui/material";
import { CENTER, Colors } from "../utils/colors";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import moment from "moment";
import { Images } from "../assets/images";

const NavigationBar = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <CssBaseline />
            <AppBar
                elevation={3}
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: '#fff',
                    width: '100%',
                }}
            >
                <Toolbar sx={{ ...CENTER, marginBottom: isMobile ? 3 : 0 }}>
                    {
                        isMobile ?
                            <Stack spacing={2} width={'100%'}>
                                <Stack
                                    width={'100%'}
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                >
                                    <img src={Images.logo} style={{ height: 'auto', width: '20%', objectFit: 'contain' }} alt="" className="" />
                                    <span style={{ color: '#999', fontWeight: '500' }}>{moment().format('dddd, DD MMMM YYYY')}</span>
                                </Stack>
                                <TextField
                                    type="search"
                                    size="small"
                                    placeholder="Cari Produk..."
                                    sx={{ bgcolor: "white", borderRadius: 1, width: '100%' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>
                            :
                            <div style={{ width: '100%', ...CENTER }}>
                                <Stack
                                    width={'90%'}
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{ padding: '10px 0' }}
                                >
                                    <img src={Images.logo} style={{ height: '10vh', width: 'auto', objectFit: 'contain' }} alt="" className="" />
                                    <TextField
                                        type="search"
                                        size="small"
                                        placeholder="Cari Produk..."
                                        sx={{ bgcolor: "white", width: '50%' }}
                                        InputProps={{
                                            style: { borderRadius: 10 },
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Icon>search</Icon>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Stack direction={'row'} gap={2} alignItems={'center'}>
                                        <div onClick={() => navigate('/auth/login')} style={{
                                            backgroundColor: '#fff',
                                            borderRadius: 10,
                                            border: `2px solid ${Colors.primary}`,
                                            height: '100%',
                                            padding: '5px 15px',
                                            ...CENTER,
                                            cursor: 'pointer'
                                        }}>
                                            <span style={{ color: Colors.primary }}>Masuk</span>
                                        </div>
                                        <div onClick={() => navigate('/auth/register')} style={{
                                            borderRadius: 10,
                                            backgroundColor: Colors.primary,
                                            height: '100%',
                                            padding: '5px 15px',
                                            ...CENTER,
                                            cursor: 'pointer'
                                        }}>
                                            <span style={{ color: '#fff' }}>Daftar</span>
                                        </div>
                                    </Stack>
                                </Stack>
                            </div>
                    }
                </Toolbar>
            </AppBar>
        </Box >
    );
};

export default NavigationBar;
