import { Stack } from '@mui/material';
import * as React from 'react';

const CategoryList = () => {
    return (
        <div style={{ border: '2px solid #909090', borderRadius: 10 }}>
            <Stack direction={"column"} gap={3} sx={{ padding: 3 }}>
                <h3 style={{ margin: 0, color: '#000', width: '100%', textAlign: 'center' }}>Kategory Pilihan</h3>
                <Stack direction={'row'} width={'100%'} alignItems={"flex-end"} justifyContent={'space-around'}>
                    {
                        [1, 2, 3, 4, 5, 6].map((item) => (
                            <Stack
                                direction={'column'}
                                key={item}
                                gap={1}
                                width={'13%'}
                                alignItems={'center'}
                                sx={{
                                    transform: 'scale(1)',
                                    transition: 'all .2s',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        transform: 'scale(1.2)',
                                        transition: 'all .2s',
                                    }
                                }}
                            >
                                <img
                                    src={'https://www.innisfree.com/id/id/resources/upload/product/36013_l.png'}
                                    alt=""
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        aspectRatio: 1,
                                        objectFit: 'contain'
                                    }}
                                />
                                <span style={{ color: '#000' }}>Lip Tint</span>
                            </Stack>
                        ))
                    }
                </Stack>
            </Stack>
        </div>
    )
}

export default CategoryList;