import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FreeMode } from 'swiper';
import ProductCard from './productCard';
import { Divider, Stack } from '@mui/material';
import { Colors } from '../utils/colors';

const BestSeller = () => {
    return (
        <Stack direction={'column'} gap={2} style={{ width: '100%' }}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <h2 style={{ margin: 0, color: '#000' }}>BESTSELLERS</h2>
                <span style={{ color: Colors.primary }}>Lihat Semua</span>
            </Stack>
            <Swiper
                slidesPerView={5.8}
                modules={[FreeMode]}
                freeMode
                spaceBetween={15}
                style={{ width: '100%', padding: 10 }}
            >
                {
                    [...Array(20)].map((item, index) => (
                        <SwiperSlide
                            key={index}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <ProductCard />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            <Divider />
        </Stack>
    )
}

export default BestSeller;