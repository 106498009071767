import React from 'react'
import Countdown from 'react-countdown';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FreeMode } from 'swiper';
import ProductCard from './productCard';
import { Divider, Stack } from '@mui/material';
import { CENTER, Colors } from '../utils/colors';

const RenderTime = ({ days, hours, minutes, seconds }: any) => {
    return (
        <Stack direction={'row'} gap={0.5} alignItems={'center'}>
            <div style={{ backgroundColor: Colors.primary, height: 35, aspectRatio: 1, ...CENTER, borderRadius: 5 }}>
                <b><span style={{ color: '#fff' }}>{days.toString()}</span></b>
            </div>
            <b><span style={{ color: '#000' }}>:</span></b>
            <div style={{ backgroundColor: Colors.primary, height: 35, aspectRatio: 1, ...CENTER, borderRadius: 5 }}>
                <b><span style={{ color: '#fff' }}>{hours.toString()}</span></b>
            </div>
            <b><span style={{ color: '#000' }}>:</span></b>
            <div style={{ backgroundColor: Colors.primary, height: 35, aspectRatio: 1, ...CENTER, borderRadius: 5 }}>
                <b><span style={{ color: '#fff' }}>{minutes.toString()}</span></b>
            </div>
            <b><span style={{ color: '#000' }}>:</span></b>
            <div style={{ backgroundColor: Colors.primary, height: 35, aspectRatio: 1, ...CENTER, borderRadius: 5 }}>
                <b><span style={{ color: '#fff' }}>{seconds.toString()}</span></b>
            </div>
        </Stack>
    )
};

const FlashSale = () => {
    return (
        <Stack direction={'column'} gap={2} style={{ width: '100%' }}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'} gap={2}>
                    <h2 style={{ margin: 0, color: '#000' }}>FLASHSALE</h2>
                    <Countdown date={'2023-12-01T00:00:00'} renderer={RenderTime} />
                </Stack>
                <span style={{ color: Colors.primary }}>Lihat Semua</span>
            </Stack>
            <Swiper
                slidesPerView={5.8}
                modules={[FreeMode]}
                freeMode
                spaceBetween={15}
                style={{ width: '100%', padding: 10 }}
            >
                {
                    [...Array(20)].map((item, index) => (
                        <SwiperSlide
                            key={index}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <ProductCard />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            <Divider />
        </Stack>
    )
}

export default FlashSale;