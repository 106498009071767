export const Colors = {
  primary: "#8e54a0",
  secondary: "#00DFA2",
  success: "#24bb33",
  error: "#FF0060",
  warning: "#F6FA70",
  inherit: "#ffe4e3",
  info: "#0068ff",
};

export const CENTER = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};