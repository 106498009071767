import { Divider, Icon, Stack, Toolbar } from '@mui/material';
import * as React from 'react';
import { Icons } from '../../assets/icons';
import BannerSwiper from '../../components/bannerSwiper';
import BestSeller from '../../components/bestSeller';
import CategoryList from '../../components/categoryList';
import FlashSale from '../../components/flashSale';
import Footer from '../../components/footer';
import NavigationBar from '../../components/navigationBar';
import { CENTER, Colors } from '../../utils/colors';

const CardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.14)',
    border: '1px solid #999',
    borderRadius: 3,
    color: '#000',
    transition: 'all .2s',
    padding: '20px 0',
}

const HomeScreen = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <NavigationBar />
            <Toolbar />
            <Toolbar />
            <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                <Stack direction={'column'} gap={5} style={{ width: '90%' }}>
                    <BannerSwiper />
                    <CategoryList />
                    <FlashSale />
                    <BestSeller />
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Stack direction={'column'} gap={1.5} alignItems={'center'} width={'28%'} sx={{ ...CardStyle, '&:hover': { backgroundColor: Colors.primary, color: '#fff', transition: 'all .2s' } }}>
                            {/* <img src={Icons.medal} style={{ width: 30, height: 30, objectFit: 'contain' }} alt="" /> */}
                            <Icon fontSize={'large'} sx={{ color: 'inherit' }}>workspace_premium</Icon>
                            <h3 style={{ margin: 0, color: 'inherit', textAlign: 'center' }}>{'Produk Terjamin'}</h3>
                            <small style={{ color: 'inherit', textAlign: 'center', width: '70%' }}>{'Belanja di AngeloSkin asli dan bersetifikat BPOM'}</small>
                        </Stack>
                        <Stack direction={'column'} gap={1.5} alignItems={'center'} width={'40%'} sx={{ ...CardStyle, '&:hover': { backgroundColor: Colors.primary, color: '#fff', transition: 'all .2s' } }}>
                            {/* <img src={Icons.rewind} style={{ width: 30, height: 30, objectFit: 'contain' }} alt="" /> */}
                            <Icon fontSize={'large'} sx={{color: 'inherit'}}>history</Icon>
                            <h3 style={{ margin: 0, color: 'inherit', textAlign: 'center' }}>{'Return & Refund'}</h3>
                            <small style={{ color: 'inherit', textAlign: 'center', width: '60%' }}>{'Jaminan & garansi '}<i>return</i>{' produk & '}<i>refund</i> uang 100% kembali</small>
                        </Stack>
                        <Stack direction={'column'} gap={1.5} alignItems={'center'} width={'28%'} sx={{ ...CardStyle, '&:hover': { backgroundColor: Colors.primary, color: '#fff', transition: 'all .2s' } }}>
                            {/* <img src={Icons.discount} style={{ width: 30, height: 30, objectFit: 'contain' }} alt="" /> */}
                            <Icon fontSize={'large'} sx={{color: 'inherit'}}>discount</Icon>
                            <h3 style={{ margin: 0, color: 'inherit', textAlign: 'center' }}>{'Weekly Beauty Discount'}</h3>
                            <small style={{ color: 'inherit', textAlign: 'center', width: '70%' }}>Diskon <i>Beauty</i> mingguan untuk produk-produk terbaik.</small>
                        </Stack>
                    </Stack>
                </Stack>
            </div>
            <div style={{
                width: '100%',
                position: 'relative',
                backgroundImage: 'url(https://cms.sehatq.com/cdn-cgi/image/format=auto,width=1080,quality=90/public/img/article_img/apakah-kosmetik-kedaluwarsa-masih-bisa-dipakai-1626152163.jpg)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', width: '100%', ...CENTER, height: '20vh' }}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '90%' }}>
                        <h1 style={{ margin: 0, color: '#fff' }}>{'Sign Up & Get 50% Off!'}</h1>
                        <div style={{ border: '1px solid #fff', borderRadius: 5, ...CENTER, width: '10%', padding: '5px 0' }}>
                            <span style={{ color: '#fff', fontWeight: '300' }}>Sign Up</span>
                        </div>
                    </Stack>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HomeScreen;