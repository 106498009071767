import { Icon, Stack } from '@mui/material';
import * as React from 'react';
import { Images } from '../assets/images';
import { CENTER } from '../utils/colors';

const About = ['Tentang Kami', 'Kebijakan Privasi', 'Blog & Artikel', 'FAQ', 'Sertifikat']
const Link = ['Instagram', 'Shopee', 'TikTok', 'Tokopedia']
const Account = ['Masuk', 'Daftar', 'Lihat Keranjang', 'Daftar Favorit', 'Bantuan']

const Footer = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fff', padding: '3% 0', ...CENTER }}>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '90%' }}>
                <Stack direction={'column'} gap={1} sx={{ width: '18%' }}>
                    <img src={Images.logo} style={{ width: '80%', height: 'auto', objectFit: 'contain' }} alt="" />
                    <small style={{ color: '#000' }}>{'©️2023, Angeloskin.\nAll Right Reserved.'}</small>
                </Stack>
                <Stack direction={'column'} gap={1} sx={{ width: '18%' }}>
                    <h3 style={{ color: '#000', margin: 0, marginBottom: 15 }}>Tentang Angeloskin</h3>
                    {
                        About.map((item, index) => (
                            <small style={{ color: '#000' }} key={index}>{item}</small>
                        ))
                    }
                </Stack>
                <Stack direction={'column'} gap={1} sx={{ width: '18%' }}>
                    <h3 style={{ color: '#000', margin: 0, marginBottom: 15 }}>Sosial Media</h3>
                    {
                        Link.map((item, index) => (
                            <small style={{ color: '#000' }} key={index}>{item}</small>
                        ))
                    }
                </Stack>
                <Stack direction={'column'} gap={1} sx={{ width: '18%' }}>
                    <h3 style={{ color: '#000', margin: 0, marginBottom: 15 }}>Akun Saya</h3>
                    {
                        Account.map((item, index) => (
                            <small style={{ color: '#000' }} key={index}>{item}</small>
                        ))
                    }
                </Stack>
                <Stack direction={'column'} gap={1} sx={{ width: '18%' }}>
                    <h3 style={{ color: '#000', margin: 0, marginBottom: 15 }}>Alamat</h3>
                    <Stack direction={'row'} gap={1}>
                        <Icon fontSize={"small"} sx={{ color: '#000' }}>pin_drop</Icon>
                        <small style={{ color: '#000' }}>{'Royal Plaza Lantai G AK2 - 01.37 (belakang MACO bakery). Jl.A.Yani Frontage Barat No 16-18, Wonokromo, Surabaya, Jawa Timur.'}</small>
                    </Stack>
                    <Stack direction={'row'} gap={1}>
                        <Icon fontSize={"small"} sx={{ color: '#000' }}>access_time</Icon>
                        <small style={{ color: '#000' }}>{'Jam Operasional: 09:00 - 18:00 WIB.'}</small>
                    </Stack>
                </Stack>
            </Stack>
        </div>
    )
}

export default Footer;