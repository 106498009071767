import * as React from 'react';
import { Button, ButtonProps, Divider, Icon, IconButton, Stack, TextField } from "@mui/material"
import { Icons } from "../assets/icons"
import { CENTER, Colors } from "../utils/colors"
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';

const SubmitButton = styled(Button)<ButtonProps>(() => ({
    color: '#fff',
    backgroundColor: Colors.primary,
    '&:hover': {
        backgroundColor: '#6d437a',
    },
}));

export const LoginSection = ({ setSection }: any) => {
    const [isPassword, setPassword] = React.useState(false)
    const navigate = useNavigate()
    
    return (
        <Stack direction={'column'} gap={4} style={{ padding: '10%' }}>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <h2 style={{ color: '#000', margin: 0 }}>Masuk Sekarang</h2>
                <span style={{ color: '#000' }}>Belum punya akun Angelo Skin?
                    <span
                        onClick={() => setSection('register')}
                        style={{
                            color: Colors.primary,
                            fontWeight: '600',
                            marginLeft: 5,
                            cursor: 'pointer'
                        }}>Daftar</span>
                </span>
            </div>
            <Stack direction={"column"} gap={2}>
                <TextField
                    type="text"
                    placeholder="Email"
                    size="small"
                    sx={{ bgcolor: "white", width: "100%" }}
                    InputProps={{ sx: { borderRadius: 1 } }}
                />
                <TextField
                    placeholder="Password"
                    size="small"
                    sx={{ bgcolor: "white", width: "100%" }}
                    type={isPassword ? 'text' : 'password'}
                    InputProps={{
                        sx: { borderRadius: 1 },
                        endAdornment: (
                            <IconButton onClick={() => setPassword(!isPassword)}>
                                <Icon sx={{ fontSize: 25, color: '#ababab' }}>{isPassword ? 'visibility_off' : 'visibility'}</Icon>
                            </IconButton>
                        )
                    }}
                />
            </Stack>
            <Stack direction={'column'}>
                <SubmitButton onClick={() => navigate('/')} variant='contained'>MASUK</SubmitButton>
                <small style={{ color: Colors.primary, marginTop: 5 }}>Lupa Password?</small>
            </Stack>
            <Divider>
                <span style={{ color: '#949494', width: '20%', textAlign: 'center' }}>ATAU</span>
            </Divider>
            <div style={{
                backgroundColor: '#fff',
                borderRadius: 5,
                border: '1px solid #000',
                width: '100%',
                padding: '10px 0',
                ...CENTER
            }}>
                <Stack direction={"row"} gap={1}>
                    <img src={Icons.google} style={{ width: 20, height: 20, objectFit: 'contain' }} alt="" />
                    <span style={{ color: '#000' }}>Google</span>
                </Stack>
            </div>
        </Stack>
    )
}

export const RegisterSection = ({ setSection }: any) => {
    const navigate = useNavigate()

    return (
        <Stack direction={'column'} gap={4} style={{ padding: '10%' }}>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <h2 style={{ color: '#000', margin: 0 }}>Daftar Sekarang</h2>
                <span style={{ color: '#000' }}>Sudah punya akun Angelo Skin?
                    <span
                        onClick={() => setSection('login')}
                        style={{
                            color: Colors.primary,
                            fontWeight: '600',
                            marginLeft: 5,
                            cursor: 'pointer'
                        }}>Masuk</span>
                </span>
            </div>
            <Stack direction={"column"} gap={2}>
                <TextField
                    type="text"
                    placeholder="Email"
                    size="small"
                    sx={{ bgcolor: "white", width: "100%" }}
                    InputProps={{ sx: { borderRadius: 1 } }}
                />
            </Stack>
            <SubmitButton onClick={() => navigate('/account/register')} variant='contained'>SELANJUTNYA</SubmitButton>
            <Divider>
                <span style={{ color: '#949494', width: '20%', textAlign: 'center' }}>ATAU</span>
            </Divider>
            <div style={{
                backgroundColor: '#fff',
                borderRadius: 5,
                border: '1px solid #000',
                width: '100%',
                padding: '10px 0',
                ...CENTER
            }}>
                <Stack direction={"row"} gap={1}>
                    <img src={Icons.google} style={{ width: 20, height: 20, objectFit: 'contain' }} alt="" />
                    <span style={{ color: '#000' }}>Google</span>
                </Stack>
            </div>
            <small style={{ color: '#000', textAlign: 'center' }}>Dengan mendaftar, anda setuju dengan
                <span style={{ color: Colors.primary, marginLeft: 5 }}>Syarat, Ketentuan dan Kebijakan dari AngeloSkin</span>
                {' & '}
                <span style={{ color: Colors.primary }}>Kebijakan Privasi</span>
            </small>
        </Stack>
    )
}