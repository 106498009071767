import { Button, ButtonProps, Icon, IconButton, Stack, TextField } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles';
import { Colors } from '../utils/colors';
import { useNavigate } from 'react-router';
import { Images } from '../assets/images';

const SubmitButton = styled(Button)<ButtonProps>(() => ({
    color: '#fff',
    backgroundColor: Colors.primary,
    '&:hover': {
        backgroundColor: '#6d437a',
    },
}));

export const OTPSection = ({ onStep }: any) => {
    const [inputRefsArray] = React.useState<any>(() =>
        Array.from({ length: 6 }, () => React.createRef())
    );
    const [currentIndex, setCurrentIndex] = React.useState(0)
    const [OTP, setOTP] = React.useState(() =>
        Array.from({ length: 6 }, () => "")
    );

    const handleInput = (e: any, index: number) => {
        const { value } = e.target;
        if (!isNaN(value) && value !== ' ') {
            setOTP((OTP) =>
                OTP.map((letter, letterIndex) =>
                    letterIndex === index ? value : letter
                )
            )
        }
    }

    const handleKeyPress = (e: any) => {
        if (!isNaN(e.key) && e.code !== 'Space') {
            setCurrentIndex((prevIndex) => {
                const nextIndex: any = prevIndex < 6 - 1 ? prevIndex + 1 : 0;
                const nextInput: any = inputRefsArray?.[nextIndex]?.current;
                nextInput.focus();
                nextInput.select();
                return nextIndex;
            });
        }
    };

    React.useEffect(() => {
        if (inputRefsArray?.[0]?.current) {
            inputRefsArray?.[0]?.current?.focus();
        }
        window.addEventListener("keyup", handleKeyPress, false);
        return () => {
            window.removeEventListener("keyup", handleKeyPress);
        };
    }, []);

    return (
        <Stack direction={'column'} gap={4} style={{ padding: '5%' }}>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <h2 style={{ color: '#000', margin: 0 }}>Masukkan Kode OTP</h2>
                <br></br>
                <span style={{ color: '#000' }}>Kode OTP telah dikirim via Email ke
                    <br></br>
                    <span style={{
                        fontWeight: '700',
                        marginLeft: 5,
                    }}>admin123@gmail.com</span>
                </span>
            </div>
            <Stack direction={"row"} alignItems={"flex-end"} justifyContent={'space-around'}>
                {
                    inputRefsArray.map((item: any, index: number) => (
                        <TextField
                            inputRef={item}
                            key={index}
                            type="text"
                            size="medium"
                            sx={{ bgcolor: "white", width: "13%" }}
                            inputProps={{
                                maxLength: 1, style: {
                                    textAlign: 'center',
                                    fontSize: '8vh'
                                },
                            }}
                            variant={'standard'}
                            style={{ width: '13%' }}
                            onChange={(e) => handleInput(e, index)}
                            onClick={(e: any) => {
                                setCurrentIndex(index);
                                e.target.select();
                            }}
                            value={OTP[index]}
                        />
                    ))
                }
            </Stack>
            <SubmitButton onClick={onStep} variant='contained'>LANJUT</SubmitButton>
        </Stack>
    )
}

export const PasswordSection = ({ onStep }: any) => {
    const [isPassword, setPassword] = React.useState(false)
    const [isConfirmPassword, setConfirmPassword] = React.useState(false)
    const [PasswordValue, setPasswordValue] = React.useState('')
    const [ConfirmPasswordValue, setConfirmPasswordValue] = React.useState('')

    const LengthValidation = () => {
        if (PasswordValue.length === 0) {
            return { icon: 'remove_circle_outline', color: '#ababab', status: false }
        } else if (PasswordValue.length < 8 || PasswordValue.length > 20) {
            return { icon: 'highlight_off', color: Colors.error, status: false }
        } else {
            return { icon: 'check_circle_outline', color: Colors.success, status: true }
        }
    }

    const AlphaNumericValidation = () => {
        if (PasswordValue.length === 0) {
            return { icon: 'remove_circle_outline', color: '#ababab', status: false }
        } else if (/^(?=.*[0-9])(?=.*[A-Za-z])[A-Za-z0-9]+$/.test(PasswordValue)) {
            return { icon: 'check_circle_outline', color: Colors.success, status: true }
        } else {
            return { icon: 'highlight_off', color: Colors.error, status: false }
        }
    }

    const ConfirmValidation = () => {
        if (ConfirmPasswordValue.length === 0) {
            return { icon: 'remove_circle_outline', color: '#ababab', status: false }
        } else if (PasswordValue === ConfirmPasswordValue) {
            return { icon: 'check_circle_outline', color: Colors.success, status: true }
        } else {
            return { icon: 'highlight_off', color: Colors.error, status: false }
        }

    }

    return (
        <Stack direction={'column'} gap={4} style={{ padding: '5%' }}>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <h2 style={{ color: '#000', margin: 0 }}>Buat Kata Sandi</h2>
            </div>
            <TextField
                label="Kata Sandi"
                size="small"
                variant={'outlined'}
                sx={{ bgcolor: "white", width: "100%" }}
                type={isPassword ? 'text' : 'password'}
                onChange={(e) => setPasswordValue(e.target.value)}
                value={PasswordValue}
                InputProps={{
                    sx: { borderRadius: 1 },
                    endAdornment: (
                        <IconButton onClick={() => setPassword(!isPassword)}>
                            <Icon sx={{ fontSize: 25, color: '#ababab' }}>{isPassword ? 'visibility_off' : 'visibility'}</Icon>
                        </IconButton>
                    )
                }}
            />
            <TextField
                label="Konfirmasi Kata Sandi"
                size="small"
                variant={'outlined'}
                sx={{ bgcolor: "white", width: "100%" }}
                onChange={(e) => setConfirmPasswordValue(e.target.value)}
                value={ConfirmPasswordValue}
                type={isConfirmPassword ? 'text' : 'password'}
                InputProps={{
                    sx: { borderRadius: 1 },
                    endAdornment: (
                        <IconButton onClick={() => setConfirmPassword(!isConfirmPassword)}>
                            <Icon sx={{ fontSize: 25, color: '#ababab' }}>{isConfirmPassword ? 'visibility_off' : 'visibility'}</Icon>
                        </IconButton>
                    )
                }}
            />
            <Stack direction={'column'} gap={1}>
                <span style={{ color: '#000' }}>Kata Sandi harus memiliki:</span>
                <Stack direction={'row'} gap={1}>
                    <Icon sx={{ color: LengthValidation().color }} fontSize={'small'}>{LengthValidation().icon}</Icon>
                    <small style={{ color: LengthValidation().color }}>8 hingga 20 karakter</small>
                </Stack>
                <Stack direction={'row'} gap={1}>
                    <Icon sx={{ color: AlphaNumericValidation().color }} fontSize={'small'}>{AlphaNumericValidation().icon}</Icon>
                    <small style={{ color: AlphaNumericValidation().color }}>Mengandung huruf dan angka</small>
                </Stack>
                <Stack direction={'row'} gap={1}>
                    <Icon sx={{ color: ConfirmValidation().color }} fontSize={'small'}>{ConfirmValidation().icon}</Icon>
                    <small style={{ color: ConfirmValidation().color }}>Konfirmasi Kata sandi</small>
                </Stack>
            </Stack>
            <SubmitButton
                disabled={
                    LengthValidation().status === false ||
                    AlphaNumericValidation().status === false ||
                    ConfirmValidation().status === false
                }
                onClick={onStep}
                variant='contained'
            >SELESAI</SubmitButton>
        </Stack >
    )
}

export const SuccessSection = () => {
    const navigate = useNavigate()
    return (
        <Stack direction={'column'} alignItems={'center'} gap={4} style={{ padding: '5%', textAlign: 'center' }}>
            <h2 style={{ color: '#000', margin: 0 }}>Terima Kasih</h2>
            <img src={Images.bagPurple} style={{ width: 80, height: 80, objectFit: 'contain' }} alt="" />
            <span style={{ color: '#000' }}>Anda telah berhasil membuat akun AngeloSkin.
                <br />
                Silahkan klik tombol <i>Sign In</i> untuk masuk ke akun Anda.
            </span>
            <SubmitButton onClick={() => navigate('/')} sx={{ width: '100%' }} variant='contained'>SIGN IN</SubmitButton>
        </Stack>
    )
}