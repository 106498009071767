import * as React from 'react';
import './styles.css'
import { CENTER } from '../../utils/colors';
import { Stack } from '@mui/material'
import moment from 'moment';
import LoginSwiper from '../../components/loginSwiper';
import { LoginSection, RegisterSection } from '../../components/loginSection';
import { Images } from '../../assets/images';
import { useParams } from 'react-router';

const LoginScreen = () => {
    const { type } = useParams()
    const [SectionActive, setSectionActive] = React.useState(type)
    const [isActive, setActive] = React.useState(type === 'login' ? 0 : 1)

    return (
        <div style={{ ...CENTER, height: '100vh', width: '100%', overflow: 'none', backgroundColor: '#fff' }}>
            <Stack
                direction={'column'}
                height={"100%"}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
            >
                <img src={Images.logo} style={{ height: '8%', width: 'auto', objectFit: 'contain', paddingTop: '1%' }} alt="" />
                <Stack direction={'row'} width={'80%'} alignItems={'center'} justifyContent={'space-between'}>
                    <LoginSwiper />
                    <div
                        style={{
                            width: '35%',
                            borderRadius: 20,
                            backgroundColor: '#fff',
                            boxShadow: '0px 0px 20px 8px rgba(0,0,0,0.1)'
                        }}
                    >
                        {
                            SectionActive === 'login' ?
                                <div className={`${isActive === 0 ? 'fadeIn' : 'fadeOut'}`}>
                                    <LoginSection setSection={(v: string) => {
                                        setActive(1)
                                        setTimeout(() => {
                                            setSectionActive(v)
                                        }, 200)
                                    }}></LoginSection>
                                </div>
                                :
                                <div className={`${isActive === 1 ? 'fadeIn' : 'fadeOut'}`}>
                                    <RegisterSection setSection={(v: string) => {
                                        setActive(0)
                                        setTimeout(() => {
                                            setSectionActive(v)
                                        }, 200)
                                    }}></RegisterSection>
                                </div>
                        }
                    </div>
                </Stack>
                <span style={{ color: '#000', paddingBottom: '1%' }}>{'©️ ' + moment().format('YYYY') + ' Angelo Skin.'}</span>
            </Stack>
        </div>
    )
}

export default LoginScreen;