import { Divider, Icon, Stack } from '@mui/material'
import React from 'react'
import { CENTER, Colors } from '../utils/colors'

const ProductCard = () => {
    return (
        <div style={{ backgroundColor: '#fff', borderRadius: 5, boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.14)', width: '100%', height: 'auto' }}>
            <div style={{ padding: 10 }}>
                <Stack direction={'column'} sx={{ width: '100%', height: '100%' }} gap={1}>
                    <img
                        src="https://id.barenbliss.com/cdn/shop/products/Peach_Makes_Perfect_Lip_Tint_first_pic_1024x1024.jpg?v=1654779679"
                        alt=""
                        style={{ width: '100%', aspectRatio: 1, objectFit: 'cover', borderRadius: 5 }}
                    />
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <div style={{ ...CENTER, padding: 5, backgroundColor: Colors.primary, borderRadius: 5 }}>
                            <small style={{ color: '#fff' }}>50% off</small>
                        </div>
                        <small style={{ color: '#999', textDecoration: 'line-through' }}>Rp 50.000</small>
                    </Stack>
                    <h3 style={{ margin: 0, color: '#000' }}>Rp 100.000</h3>
                    <span style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        fontSize: '2vh'
                    }}>Reprehenderit et veniam ullamco magna cupidatat Lorem deserunt dolore sint.</span>
                    <Stack direction={'row'} gap={1}>
                        <Stack direction={'row'} gap={0.5}>
                            <Icon fontSize='small' sx={{ color: '#e5e437' }}>star</Icon>
                            <small style={{ color: '#999' }}>4.8</small>
                        </Stack>
                        <Divider orientation="vertical" variant="fullWidth" flexItem />
                        <small style={{ color: Colors.primary }}>{'(1.200 Terjual)'}</small>
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}

export default ProductCard