import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper';
import { Images } from '../assets/images';

const BannerSwiper = () => {
    return (
        <div style={{ width: '100%' }}>
            <Swiper
                pagination={true}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: true
                }}
                loop={true}
                slidesPerView={1}
                modules={[Pagination, Autoplay]}
                style={{ width: '100%', height: '50vh', borderRadius: 20 }}
            >
                {
                    [1, 2, 2, 3, 4, 4].map((item, index) => (
                        <SwiperSlide
                            key={index}
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                cursor: 'pointer',
                                textAlign: 'left'
                            }}>
                            <img
                                src={Images.banner}
                                style={{
                                    height: '100%',
                                    width: "100%",
                                    objectFit: "cover",
                                    position: 'relative'
                                }}
                                alt={index.toString()}
                            ></img>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div >
    )
}

export default BannerSwiper;