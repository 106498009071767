import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper';

const LoginSwiper = () => {
    return (
        <div style={{ width: '58%' }}>
            <Swiper
                pagination={true}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: true
                }}
                loop={true}
                slidesPerView={1}
                modules={[Pagination, Autoplay]}
                style={{ width: '100%', height: 'auto', aspectRatio: 16 / 9, borderRadius: 20 }}
            >
                {
                    [1, 2, 2, 3, 4, 4].map((item, index) => (
                        <SwiperSlide
                            key={index}
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                cursor: 'pointer',
                                textAlign: 'left'
                            }}>
                            <img
                                src={'https://media.istockphoto.com/id/1257444093/id/vektor/mist-semprotkan-spanduk-iklan-botol-kosmetik-poster-promo.jpg?s=170667a&w=0&k=20&c=Uk8LALteH273_7y1BGHPbVuvh3ZFww_FzQ2cY1NHyrI='}
                                style={{
                                    height: '100%',
                                    width: "100%",
                                    objectFit: "cover",
                                    position: 'relative'
                                }}
                                alt={index.toString()}
                            ></img>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div >
    )
}

export default LoginSwiper;